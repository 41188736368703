import '@/styles/_base.scss';
import PrismicVue from '@prismicio/vue';
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import CountryFlag from 'vue-country-flag';
import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';
import './registerServiceWorker';


const endpoint = 'https://hammza.cdn.prismic.io/api/v2';

Vue.config.productionTip = false;
Vue.use(Tooltip);
Vue.component('country-flag', CountryFlag);
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}


console.log(`

  |__| _  _ _  _  _
  |  |(_|| | | /_(_|`);


Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { router },
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
