<!-- eslint-disable -->
<template>
<article :class="{normalMode: !changePerspective}"  @mousemove="getMouseCardValues" @mouseleave="resetMouseCardValues"  class="project" :style="{ backgroundImage: `url(${project.data.mainimage.url})` }">
  <!-- v-for dos projetos-->
  <router-link class="project__linkContainer" :to="{ name: 'projectDetail', params: {uid: project.uid},  }">
    <div class="project__container ">
      <h3 class="project__name">{{ project.data.name[0].text }}<span>_</span></h3>
    </div>
  </router-link>

  <!-- Nome do projeto -->
</article>
</template>

<script>
/* eslint-disable */
// eslint-disable
export default {
  name: 'project',
  props: ['project'],
  methods: {
    getMouseCardValues(event) {
      if (this.changePerspective) {
          let x;
          let y;
          const root = document.documentElement;
          const mouseX = event.clientX  - event.currentTarget.getBoundingClientRect().left;
          const mouseY = event.clientY - event.currentTarget.getBoundingClientRect().top;
          const width = event.currentTarget.offsetWidth;
          const height = event.currentTarget.offsetHeight;
          const reverse = 1;
          const max = 30;
          x = mouseX / width;
          y = mouseY / height;

          let xAngle = (reverse * (max - x * max * 2)).toFixed(2);
          let yAngle = (reverse * (y * max * 2 - max)).toFixed(2);

        event.currentTarget.style.transform = `perspective(1000px) rotateX(${yAngle}deg) rotateY(${xAngle}deg)`;

      }
    },
    resetMouseCardValues(event) {
      const root = document.documentElement;
      const xAngle = 0;
      const yAngle = 0;

      if (this.changePerspective) {
        event.currentTarget.style.transform = `perspective(1000px) rotateX(0deg) rotateY(35deg) rotateZ(0) translateZ(0)`;
      }

    },
  },

  computed: {
    changePerspective() {
      return this.$store.getters.changePerspective;
    }
  },
  updated(){
    if(!this.changePerspective){
      document.querySelectorAll('.project').forEach((element) =>{
         element.style.transform = `perspective(0px) rotateX(0deg) rotateY(0deg) rotateZ(0) translateZ(0)`;
      })
    }else{
      document.querySelectorAll('.project').forEach((element) =>{
         element.style.transform = `perspective(1000px) rotateX(0deg) rotateY(35deg) rotateZ(0) translateZ(0)`;
      })


    }
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style  lang="scss">
@import '@/styles/_variables.scss';

.project {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    transform-style: preserve-3d;
    outline: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s ease;
    background-size: cover;
    background-position: center;
    transition: all 0.3s ease;


    &.normalMode{
      transform: perspective(0px) rotateX(0deg) rotateY(0deg) rotateZ(0) translateZ(0);
    }

    :root {
        --rotateProjectX: 0;
        --rotateProjectY: 0;
        --rotateProjectZ: 0;
    }

    &__linkContainer {
        width: 100%;
        height: 100%;

        &:after {
            content: none;
        }
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, $darkBlack 20%, transparent 80%);
        background-position: 0 -20px;
        background-size: 1px 508px;
        opacity: 1;
        transition: all 0.4s ease-out;
    }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $blue;
        z-index: -1;
        transform: translateZ(-1px);
        transform-origin: left bottom;
        transition: all 0.3s 0.2s ease;
    }

    &:hover {

        transform: translateY(-5px);
        transition: all 0.5s ease;
        box-shadow: 11px 8px 20px 0 rgba(0,0,0,0.24);

        .project__container {
            z-index: 1;
        }

        .project__icons,
        .project__labelContainer {
            opacity: 1;
        }

        &:before {
            transform: translate3d(10px, 10px, -1px);
        }

        &:after {
            background-size: 1px 800px;
            background-position: 0 -326px;
            opacity: 1;
        }

        .project__name{
          span{
            animation: text 0.3s infinite
          
          }
        }

    }

    &__container {
        transform: translateZ(30px);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;


    }

    &__icons {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        padding-top: $s-8;
        padding-bottom: $m-5;
        transition: all 0.3s 0.1s ease;
    }

    &__icon {
        margin-right: $s-7;

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            svg {
                fill: $blue;
            }

            .iconName {
                opacity: 1;
                transform-origin: center;
                transform: translateX(-50%) rotate(10deg);
            }
        }

        &:after {
            content: none;
        }
    }

    &__labelContainer {
        opacity: 0;
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 50%;
            width: 100px;
            height: 2px;
            background-color: $blue;
            transform: translateX(-50%);
        }
    }

    &__label {
        border-radius: 10px;
        background-color: #1b191a;
        font-family: $louisGeorgeBold;
        letter-spacing: 2.2px;
        color: $white;
        padding: 5px;
        margin-right: 10px;
        font-size: $font-size1;

        &:last-of-type {
            margin-right: 0;
        }

    }

    &__name {
        position: relative;
        font-family: $louisGeorgeBold;
        font-size: $font-size6;
        color: $white;
        letter-spacing: 3.2px;
        padding: $s-9 $s-10;
        z-index: 1;
        line-height: 1.4;

        span{
          opacity: 0;
        }
    }

    .iconName {
        position: absolute;
        top: -15px;
        left: 50%;
        font-family: $fiveBoroughs;
        color: $blue;
        transform: translateX(-50%) rotate(0deg);
        font-size: $font-size3;
        letter-spacing: 3px;
        opacity: 0;
        transition: all 0.3s ease;
        display: inline-block;
    }

    svg {
        fill: $white;
    }

}
</style>
