import Vue from 'vue';
import Vuex from 'vuex';
/* eslint-disable */

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projects: [
      
      {
        id: 6,
        name: "Flash Group Blog",
        labels: ["GCP", "WordPress", "PHP", "Cpanel", "Cloudflare", "MySQL", "Crypto"],
        image: "https://cdn.hammza.com/projects/webp/blogflash-1.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/blogflash-1.webp",
            description: "Landing Page"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/blogflash-2.webp",
            description: "Blog"
          }
        ],
        country:["gb"],
        description:
          "Flash Group Blog is site dedicated to news related to Crypto and all about Ozeety Coin.",
        liveSite: "https://blog.flashgroup.io/",
      },
      {
        id: 9,
        name: "let's End Hepatitis",
        labels: ["AWS", "Docker", "Npm", "Email-Markiting", "React", "Awarness", "Ec2", "S3"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-02%2011-13-24.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-02%2011-13-24.png.webp",
            description: "Landing Page"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-02%2011-13-13.png.webp",
            description: "About"
          },
        ],
        country:["in"],
        description:
          "An initiative for Awareness of Hepatitis in India.",
        liveSite: "https://www.letsendhepatitis.com/",
      },
      {
        id: 10,
        name: "Flashmoni",
        labels: ["DigitalOcean", "Jenkins", "CI/CD", "Code-Pipline", "Scripting", "Automation", "Groovy", "Docker", "Npm"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-32-04.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-32-04.png.webp",
            description: "Main Page"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-32-11.png.webp",
            description: "Overview"
          },
        ],
        country:["gb"],
        description:
          "Flashmoni is a Blockchain-powered fintech company that offers a physical gold-pegged cryptocurrency, innovative payment solutions and smart contract-based advertising solutions.",
        liveSite: "https://www.flashmoni.io/",
      },
      {
        id: 12,
        name: "FlashXchanger",
        labels: ["React", "Jenkins", "DigitalOcean", "Docker", "Groovy", "CI/CD", "Npm"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-33-23.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-33-23.png.webp",
            description: "one screen on Daily tab"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-33-44.png.webp",
            description: "Workings"
          },
        ],
        country:["pt"],
        description:
          "This website is dedicated to live pricing for Flashmoni Exchange and here you can buy their packages.",
        liveSite: "https://flashxchanger.com/",
      },
      {
        id: 13,
        name: "Sapian Capital",
        labels: ["React", "Node", "Jenkins", "DigitalOcean"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-34-00.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-34-00.png.webp",
            description: "main screen"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-34-11.png.webp",
            description: "Workflow"
          },
        ],
        country:["pt"],
        description:
          "SAPIAN CAPITAL OTC is the first OTC desk offering Cryptomonial Engineering. It allows large transactions, Options, Futures and much more.",
        liveSite: "https://sapiancapital.com/",
      },
      {
        id: 14,
        name: "Wiliex",
        labels: ["React", "Sass", "Jitsi", "DigitalOcean", "WebRTC", "Open Source", "Virtual Meetings", "Dockers"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-35-08.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-35-08.png.webp",
            description: "main screen"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-54-03.png.webp",
            description: "Video Calling"
          }
        ],
        country:["eg"],
        description:
          "Wiliex GoLive, It's an app for virtual meetings just like Zoom.",
        liveSite: "https://wiliex.com/",
      },
      {
        id: 15,
        name: "gozappi",
        labels: ["Laravel", "AWS CodeCommit", "AWS CodeBuild", "AWS CodePipeline", "AWS EC2", "CI/CD", "DevOps"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-35-19.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-35-19.png.webp",
            description: "main screen"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-35-44.png.webp",
            description: "Overview"
          },
        ],
        country:["au"],
        description:
          "GoZappi is a one stop shop for all your sales and marketing, and it works on autopilot for most of the time. So you can get on with running your business while GoZappi gets on with generating new business.",
        liveSite: "https://www.gozappi.com.au/",
      },
      {
        id: 16,
        name: "GroupPal",
        labels: ["Laravel", "AWS Route53", "AWS Cloudfront", "AWS EBS", "SSL", "AWS RDS"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-58-20.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-58-20.png.webp",
            description: "main screen"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-58-36.png.webp",
            description: "Video Calling"
          }
        ],
        country:["in"],
        description:
          "GroupPal is an online Business Acquisition Application helping Clients to retain customers without tech skills, earn from many field without investments and making your business stand out from Billion Dollar Tech Competitors.",
        liveSite: "https://grouppal.in/",
      },
      {
        id: 17,
        name: "Coursevera",
        labels: ["Laravel", "AWS S3", "Storage", "AWS EC2", "Education"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-59-17.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-59-17.png.webp",
            description: "main screen"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2015-59-56.png.webp",
            description: "Video Calling"
          },
        ],
        country:["in"],
        description:
          "CourseVera is an E-Learning platform, especially made for people who wish to LEARN anything in their own mother-tongue.",
        liveSite: "https://coursevera.com/",
      },
      {
        id: 18,
        name: "Vonza",
        labels: ["React", "AWS S3", "AWS Cloudfront", "AWS EC2", "Jenkins", "AWS DocumentDB", "CI/CD", "Node", "Npm"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2016-10-16.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2016-10-16.png.webp",
            description: "main screen"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2016-09-45.png.webp",
            description: "Video Calling"
          }
        ],
        country:["us"],
        description:
          "The Easy Way To Build An Online Business Create and sell online courses, offer digital and physical products, construct your sales funnels and build an amazing website.",
        liveSite: "https://coursevera.com/",
      },
      {
        id: 19,
        name: "AdsTarget",
        labels: ["Wordpress", "AWS EC2", "WHM", "Cpanel", "Let'sEncrypt", "Shared Hosting"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2016-00-01.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2016-00-01.png.webp",
            description: "main screen"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2016-00-08.png.webp",
            description: "Video Calling"
          }
        ],
        country:["us","in","lt"],
        description:
          "AdsTargets is a hassle-free online advertising and monetising solution for both advertisers and publishers.",
        liveSite: "https://adstargets.com/",
      },
      {
        id: 20,
        name: "Mekdar",
        labels: ["Laravel", "Cpanel", "Video Meetingd", "MySql", "Messaging", "PayPal"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2016-00-11.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2016-00-11.png.webp",
            description: "main screen"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-09-07%2016-01-22.png.webp",
            description: "Video Calling"
          }
        ],
        country:["eg"],
        description:
          "Mekdar is an online Diet-Planing platform, their mission is to apply diet in a more practical and effective way, to help people live healthier lives.Making Diet easy with “Mekdar”",
        liveSite: "https://www.mekdar.com/",
      },
      {
        id: 21,
        name: "RateAStay",
        labels: ["Laravel", "Cpanel", "Video Meetingd", "MySql", "Messaging", "PayPal"],
        image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-07-20%2017-51-49.png.webp",
        imagesSlider: [
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-07-20%2017-51-49.png.webp",
            description: "main screen"
          },
          {
            image: "https://cdn.hammza.com/projects/webp/Screenshot%20from%202020-07-21%2017-54-05.png.webp",
            description: "Video Calling"
          }
        ],
        country:["us"],
        description:
          "RateAStay is an online platform where you can rate your un-biased experience of airbnb stay.",
        liveSite: "https://www.rateastay.com/",
      }
    ],
    interests: [
      {
        id: 0,
        image: "https://cdn.hammza.com/interests/webp/new-8.webp",
        description: "Naltar",
        isSelected: false
      },
      {
        id: 1,
        image: "https://cdn.hammza.com/interests/webp/new-4.webp",
        description: "Naltar",
        isSelected: false
      },
      {
        id: 2,
        image: "https://cdn.hammza.com/interests/webp/new-5.webp",
        description: "Naltar",
        isSelected: false
      },
      {
        id: 3,
        image: "https://cdn.hammza.com/interests/webp/new-6.webp",
        description: "Naltar",
        isSelected: false
      },
      {
        id: 4,
        image: "https://cdn.hammza.com/interests/webp/new-3.webp",
        description: "Naltar",
        isSelected: false
      },
      {
        id: 5,
        image: "https://cdn.hammza.com/interests/webp/new-1.webp",
        description: "Naltar",
        isSelected: false
      },
      {
        id: 6,
        image: "https://cdn.hammza.com/interests/webp/new-2.webp",
        description: "Naltar",
        isSelected: false
      },
      {
        id: 7,
        image: "https://cdn.hammza.com/interests/webp/new-7.webp",
        description: "Naltar",
        isSelected: false
      },
      {
        id: 8,
        image: "https://cdn.hammza.com/interests/webp/org_a681ed4908fd5181_1598141284000-01.jpeg.webp",
        description: "Hudiara",
        isSelected: false
      },
      {
        id: 9,
        image: "https://d33wwu3ublv5p2.cloudfront.net/interests/webp/IMG_20200907_125802_097.jpg.webp",
        description: "Hudiara",
        isSelected: false
      },
      {
        id: 10,
        image: "https://d33wwu3ublv5p2.cloudfront.net/interests/webp/LRM_EXPORT_156062828773070_20200728_182538457.jpeg.webp",
        description: "Hudiara Birdeye",
        isSelected: false
      },
      {
        id: 11,
        image: "https://d33wwu3ublv5p2.cloudfront.net/interests/webp/IMG_20200421_141947_575.jpg.webp",
        description: "Hudiara Fields",
        isSelected: false
      },
      {
        id: 12,
        image: "https://cdn.hammza.com/interests/webp/LRM_EXPORT_604531277677937_20200808_001316924.jpeg.webp",
        description: "Talwar Chowk, Bahria Town",
        isSelected: false
      },
      {
        id: 13,
        image: "https://cdn.hammza.com/interests/webp/LRM_EXPORT_36427554169275_20200331_133839818.jpeg.webp",
        description: "Orange Line Metro train",
        isSelected: false
      },
      {
        id: 14,
        image: "https://cdn.hammza.com/interests/webp/LRM_EXPORT_45310251246814_20191023_170323533-03.jpeg.webp",
        description: "Azad Kashmir",
        isSelected: false
      },
      {
        id: 15,
        image: "https://cdn.hammza.com/interests/webp/IMG_20191005_174445_492.jpg.webp",
        description: "Rati Gali, KPK",
        isSelected: false
      },
      {
        id: 16,
        image: "https://cdn.hammza.com/interests/webp/IMG_20190930_191919.jpg.webp",
        description: "Kalandar",
        isSelected: false
      },
      {
        id: 17,
        image: "https://cdn.hammza.com/interests/webp/LRM_EXPORT_90783749506196_20200413_130009041.jpeg.webp",
        description: "Stars",
        isSelected: false
      },
      {
        id: 18,
        image: "https://cdn.hammza.com/interests/webp/IMG_20190317_124028_201.jpg.webp",
        description: "Hunza",
        isSelected: false
      },
      {
        id: 19,
        image: "https://cdn.hammza.com/interests/webp/IMG_20190217_202615_714.jpg.webp",
        description: "Leaf",
        isSelected: false
      },
      {
        id: 20,
        image: "https://cdn.hammza.com/interests/webp/LRM_EXPORT_16612987659647_20200401_212914434.jpeg.webp",
        description: "Rakaposhi",
        isSelected: false
      },
      {
        id: 21,
        image: "https://cdn.hammza.com/interests/webp/IMG_20181210_194500_233.jpg.webp",
        description: "Baltit Fort, Hunza",
        isSelected: false
      },
      {
        id: 22,
        image: "https://cdn.hammza.com/interests/webp/IMG_20181002_232639_132.jpg.webp",
        description: "Karakoram Highway",
        isSelected: false
      },
      {
        id: 23,
        image: "https://cdn.hammza.com/interests/webp/IMG_20190202_211318-01.jpeg.webp",
        description: "Punjab University College Of Information & Technology",
        isSelected: false
      },
      {
        id: 24,
        image: "https://cdn.hammza.com/interests/webp/IMG_20180926_233608_507.jpg.webp",
        description: "Bees - Babusar Top",
        isSelected: false
      },
      {
        id: 25,
        image: "https://cdn.hammza.com/interests/webp/20190817_111618.jpg.webp",
        description: "Patterns",
        isSelected: false
      },
      {
        id: 26,
        image: "https://cdn.hammza.com/interests/webp/201710058870415056130118468.jpg.webp",
        description: "Wheat",
        isSelected: false
      },
      {
        id: 27,
        image: "https://cdn.hammza.com/interests/webp/IMG_20170323_195132_073.jpg.webp",
        description: "Flower",
        isSelected: false
      },
      {
        id: 28,
        image: "https://cdn.hammza.com/interests/webp/IMG_20170506_142226-01.jpeg.webp",
        description: "Khavaa",
        isSelected: false
      },
      {
        id: 29,
        image: "https://cdn.hammza.com/interests/webp/IMG_20171005_230441_009.jpg.webp",
        description: "Sunset",
        isSelected: false
      },
      {
        id: 30,
        image: "https://cdn.hammza.com/interests/webp/IMG_20171207_155914_859-01.jpeg.webp",
        description: "Sunset",
        isSelected: false
      },
      {
        id: 31,
        image: "https://cdn.hammza.com/interests/webp/IMG_20180110_170010-01-01.jpeg.webp",
        description: "Sky",
        isSelected: false
      },
      {
        id: 32,
        image: "https://cdn.hammza.com/interests/webp/IMG_20180213_165425_717.jpg.webp",
        description: "Sarsoo",
        isSelected: false
      },
      {
        id: 33,
        image: "https://cdn.hammza.com/interests/webp/IMG_20181126_160431-EFFECTS.jpg.webp",
        description: "Flowers",
        isSelected: false
      },
      {
        id: 34,
        image: "https://cdn.hammza.com/interests/webp/IMG_20181227_214353_699.jpg.webp",
        description: "Flowers",
        isSelected: false
      },
      {
        id: 35,
        image: "https://cdn.hammza.com/interests/webp/IMG_20200406_224954_926.jpg.webp",
        description: "sunset",
        isSelected: false
      },
      {
        id: 36,
        image: "https://cdn.hammza.com/interests/webp/IMG_20200412_182058-01.jpeg.webp",
        description: "sunset",
        isSelected: false
      },
      {
        id: 37,
        image: "https://cdn.hammza.com/interests/webp/LRM_EXPORT_16249513563123_20190213_202817845.jpeg.webp",
        description: "sunset",
        isSelected: false
      },
    ],
    myImage: "https://cdn.hammza.com/potrait/webp/cropped.png.webp",
    illustrationImage: "https://cdn.hammza.com/potrait/webp/Portrait.png.webp",
    illustrationDescription: "Illustration of me",
    socialIcons: {
      github: {
        icon: "/../assets/images/Github.svg",
        name: "GitHub",
        link: "https://github.com/haaamza"
      },
      linkedin: {
        icon: "/../assets/images/LinkedIn.svg",
        name: "Linkedin",
        link: "https://www.linkedin.com/in/hammzagee"
      },
      // fiverr: {
      //   icon: "/../assets/images/fiverr.svg",
      //   name: "Fiverr",
      //   link: "https://www.fiverr.com/hammzagee"
      // },
      upwork: {
        icon: "/../assets/images/upwork.svg",
        name: "Upwork",
        link: "https://www.upwork.com/freelancers/~019e3c010b12ae2032"
      },
      instagram: {
        icon: "/../assets/images/Instagram.svg",
        name: "Instagram",
        link: "https://www.instagram.com/hammzagee/"
      },
      calendly: {
        icon: "/../assets/images/calendly.svg",
        name: "Schedule Meeting",
        link: "https://calendar.app.google/WpA3iNSMs8Uu1Ve1A"
      }
      // '500px': {
      //   icon: "/../assets/images/500px.svg",
      //   name: "500px",
      //   link: "https://500px.com/hamzagee"
      // },
      // cv: {
      //   icon: "/../assets/images/iOSPaper.svg",
      //   name: "CV",
      //   link: "/../assets/images/joaobairradaResume.pdf"
      // }
    },
    aboutMePhoto: "https://cdn.hammza.com/potrait/webp/Low.png.webp",
    about: {
      language: {
        id: 0,
        image: "https://cdn.hammza.com/potrait/webp/Language.png.webp",
        text: "Urdu, English and Spanish"
      },
      mouse: {
        id: 1,
        image: "https://cdn.hammza.com/potrait/webp/Mouse.png.webp",
        text: "Currently working as Full-time Freelancer"
      },
      photo: {
        id: 2,
        image: "https://cdn.hammza.com/potrait/webp/Photo.png.webp",
        text: "Photography and Coding"
      },
      keyboard: {
        id: 3,
        image: "https://cdn.hammza.com/potrait/webp/Keyboard.png.webp",
        text: "Skills: Dockers, AWS, CI/CD, Git, Elastic Load Balancing, Web Development, Python, Ruby and PHP"
      },
      roots: {
        id: 4,
        image: "https://cdn.hammza.com/potrait/webp/Roots.png.webp",
        text: "Lahore, Pakistan"
      },
      diploma: {
        id: 5,
        image: "https://cdn.hammza.com/potrait/webp/Scroll.png.webp",
        text: "Bachelors in IT"
      },
      brain: {
        id: 6,
        image: "https://cdn.hammza.com/potrait/webp/Brain.png.webp",
        text: "I'm a DevOps Engineer"
      }
    },
    projectsToShow: 6,
    interestsToShow: 6,
    transition: true,
    changePerspective: false,
    perspectiveText: "3D Mode",
    isMobile: false,
    rangeSliderValue: null,
    maxRangeSliderValue: 2,
    isIntroVisible: false,
    projectImageSlider: 0
  },
  getters: {
    showMoreProjects: state => state.projectsToShow + 3,
    showMoreInterests: state => state.interestsToShow + 3,
    showModal: state => {
      for (let i = 0; i < state.interests.length; i++) {
        if (state.interests[i].isSelected) return state.interests[i];
      }
    },
    getProject: state => id =>
      state.projects.find(project => project.id === id),
    routeTransition: state => state.transition,
    changePerspective: state => state.changePerspective,
    getRangeSliderValue: state => state.rangeSliderValue,
    isMobile: state => state.isMobile,
    isIntroVisible: state => state.isIntroVisible,
    getImageSlider: state => state.projectImageSlider
  },
  mutations: {
    updateProjectsToShow: (state, payload) => {
      state.projectsToShow = payload;
    },
    updateInterestsToShow: (state, payload) => {
      state.interestsToShow = payload;
    },
    // isVisible: (state, payload) => {
    //  state.isVisible = payload
    //  console.log(state);
    // },
    isVisible: (state, payload) => {
      state.interests.forEach(element => {
        element.isSelected = false;
      });
      state.interests[payload].isSelected = true;
    },
    isHidden: (state, payload) => {
      state.interests[payload].isSelected = false;
    },
    updateTransition: (state, payload) => {
      state.transition = payload;
    },
    updateChangePerspective: (state, payload) => {
      state.changePerspective = payload;
      if (state.changePerspective) {
        state.perspectiveText = "Normal Mode";
      } else {
        state.perspectiveText = "3D Mode";
      }
    },
    updateRangeSliderValue: (state, payload) => {
      state.rangeSliderValue = payload;
    },
    isMobile: (state, payload) => {
      state.isMobile = payload;
    },
    isIntroVisible: (state, payload) => {
      state.isIntroVisible = payload;
    },
    getProjectImage: (state, payload) => {
      state.projectImageSlider = payload;
    }
  }
});
